import { defineStore } from "pinia";
import { ref } from "vue";
// import { getCmsHomePage as HHHTT } from "~~/api/strapi-cms/homepage";
import { CMS_ENV } from "~~/constants/env";
import { useCMSURLs } from "~~/constants/cms.urls";
import { getRedis, setRedis } from "../../api/redis";
import { useUserStore } from "~~/store/UserStore";
export const useCmsStore = defineStore("cms", () => {
  const homePage = ref({});
  const sliderImages = ref([]);
  const categoryImages = ref([]);
  const producTypeImages = ref([]);
  const brandImages = ref([]);
  const searchImages = ref([]);
  const licenseImages = ref([]);
  // main cover shows sliding images
  const coverSlider = ref([]);
  const cover = ref({});
  const chainCovers = ref([]);
  const CMSURL = useCMSURLs[CMS_ENV].PATH;
  const userStore = useUserStore();
  const cldUser = computed(() => userStore.cldUser);
  const getHomePage = async (selectedLanguage = "en_GB") => {
    getRedis("cms-nuxt-home").then((fromCache) => {
      if (fromCache) {
        homePage.value = fromCache.homePage;
        sliderImages.value = fromCache.sliderImages;
        brandImages.value = fromCache.brandImages;
        categoryImages.value = fromCache.categoryImages;
        producTypeImages.value = fromCache.producTypeImages;
        chainCovers.value = fromCache.chainCovers;
        // searchImages.value = fromCache.searchImages;
        cover.value = fromCache.cover;
        if (
          cldUser.value &&
          cldUser.value.Chain &&
          chainCovers.value &&
          chainCovers.value.length
        ) {
          const cc = chainCovers.value.find(
            (x) => x.chainId === cldUser.value.Chain
          );
          if (cc) {
            cover.value = cc;
          }
        }
      }
    });

    const { data } = await getCmsHomePage(selectedLanguage.replace("_", "-"));

    const { attributes } = data.value.data;

    // console.log("attributes.swiper", attributes.swiper[0]);
    homePage.value = attributes;
    cover.value = {
      ...attributes.cover,
      image: attributes.cover.image?.data?.attributes,
    };
    sliderImages.value = attributes.swiper
      .map((x) => ({
        image: (x.image.data && x.image.data.attributes) || null,
        slug: x.slug,
        id: x.id,
        url: x.url,
      }))
      .filter((x) => x.image);
    // console.log("sliderImages,,,,", sliderImages.value);
    brandImages.value = attributes.brand.map((x) => ({
      image: x.image.data.attributes,
      slug: x.slug,
      url: x.url,
    }));

    categoryImages.value = attributes.category.map((x) => ({
      image: x.image.data.attributes,
      slug: x.slug,
      url: x.url,
    }));
    producTypeImages.value = attributes.productype.map((x) => ({
      image: x.image.data.attributes,
      slug: x.slug,
    }));

    chainCovers.value = attributes.chain_cover.map((x) => ({
      image: x.image.data.attributes,
      slug: x.slug,
      chainId: x.chain,
    }));
    // console.log("data sscc", sliderImages);
    // searchImages.value = attributes.main_search.map((x) => ({
    //   image: x.image.data.attributes,
    //   slug: x.slug,
    // }));

    if (
      cldUser.value &&
      cldUser.value.Chain &&
      chainCovers.value &&
      chainCovers.value.length
    ) {
      const cc = chainCovers.value.find(
        (x) => x.chainId === cldUser.value.Chain
      );
      if (cc) {
        cover.value = { image: cc.image, ...cc };
      }
    }

    setRedis({
      key: "cms-nuxt-home",
      value: {
        homePage: homePage.value,
        sliderImages: sliderImages.value,
        brandImages: brandImages.value,
        categoryImages: categoryImages.value,
        producTypeImages: producTypeImages.value,
        // searchImages: searchImages.value,
        chainCovers: chainCovers.value,
        cover: cover.value,
      },
    }).then(() => console.log("HomePage settled in cache"));
  };

  //#region HTTP FUNC

  const getCmsHomePage = async (locale) => {
    const accessToken = useCMSURLs[CMS_ENV].PUBLIC_API_TOKEN;

    const url = `${useCMSURLs[CMS_ENV].HOME_PAGE}?populate=swiper.image,category.image,productype.image,brand.image,chain_cover.image,cover.image,license.image`; //&locale=en-GB&locale=${locale}`;
    // console.log("url", url);
    const { data, error } = await useFetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${accessToken}`,
      },
    });
    // console.log("error", error.value, data.value);
    return { data };
    // return { data: data.value.data };
  };
  const getCoverSliders = async (selectedLanguage = "en_GB") => {
    const url = `${useCMSURLs[CMS_ENV].HOME_PAGE}?populate=cover_slider.image`;
    const { data } = await getCmsResource({
      locale: selectedLanguage.replace("_", "-"),
      url: url,
    });

    const { attributes } = data.value.data;
    coverSlider.value = attributes.cover_slider.map((x) => ({
      image: x.image.data && x.image.data.attributes,
      url: x.url,
      slug: x.slug,
    }));
  };
  const getLicense = async (selectedLanguage = "en_GB") => {
    // getRedis("cms-nuxt-license").then((fromCache) => {
    //   if (fromCache) {
    //     license.value = fromCache.license;
    //   }
    // });
    const url = `${useCMSURLs[CMS_ENV].HOME_PAGE}?populate=license.image`; //&locale=en-GB&locale=${locale}`;
    const { data } = await getCmsResource({
      locale: selectedLanguage.replace("_", "-"),
      url: url,
    });

    const { attributes } = data.value.data;
    licenseImages.value = attributes.license.map((x) => ({
      image: x.image.data && x.image.data.attributes,
      url: x.url,
      slug: x.slug,
    }));
    // setRedis({
    //   key: "cms-nuxt-license",
    //   value: {
    //     license: licenseImages.value,
    //   },
    // }).then(() => console.log("cms-nuxt-license settled in cache"));
  };
  const getCmsResource = async ({ locale, url }) => {
    const accessToken = useCMSURLs[CMS_ENV].PUBLIC_API_TOKEN;
    const { data, error } = await useFetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${accessToken}`,
      },
    });
    return { data };
  };

  //#endregion
  const getImageUrl = (url) => `${CMSURL}${url}`;
  return {
    getHomePage,
    getLicense,
    getImageUrl,
    getCoverSliders,
    sliderImages,
    searchImages,
    categoryImages,
    producTypeImages,
    brandImages,
    cover,
    homePage,
    licenseImages,
    coverSlider,
  };
});
